<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

    <div class="row justify-content-md-center">

      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='mailListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>
              <div class="row">
                <div class="col-12 col-md-12">
                  <div class="py-1">
                    <div class="md-form mb-0">
                      <input  ref="origin"  id="origin" :value="filters.filOrigem" class="md-form-control" type="text">
                      <label :class="{'active': filters.filOrigem}">{{ $t('Origem/Destino') }}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">
                        {{ $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList"/>
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!-- Table -->
                <custom-vue-table
                  ref="OriginDestinationTable"
                  http-method="get"
                  :api-url="loadEndpoint"
                  :fields="fieldsTable"
                  :sort-order="sortOrder"
                  :append-params="activeFilters"
                  track-by="id"
                >
                </custom-vue-table>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomVueTable from '@/components/Table/CustomVueTable'
import FilterSideBar from '@/components/FilterSideBar'
import FilterContainer from '@/components/FilterContainer'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import TaxLogisticService from '@/services/TaxLogisticService'

export default {
  name: 'IntegrationMaerskTaxesList',
  metaInfo () {
    return {
      titleTemplate: this.$route.meta.titleText
      // titleTemplate: this.$i18n.t('sidebar.mail-status') + ' - %s'
    }
  },
  data () {
    return {
      loadEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/integration/maersk/origin/destination',
      sortOrder: [
        { field: 'nome_portal', direction: 'asc' }
      ],
      isLoading: false,
      fullPage: true,
      selectedProcess: null,
      processDetail: false,

      multiSort: true,
      activeFilterList: {
        origin: true
      },
      filter: {},
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    FilterSideBar,
    CustomVueTable,
    Loading,
    FilterContainer
  },
  // Apply filters to local filter
  beforeMount () {
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    this.$jQuery('.date-filter').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    buildFilters () {
      // Origins and destination
      this.filter.origin = this.filters.filOrigem
    },
    handleLoadError (response) {
    },
    // Loader
    applyFilter () {
      // Origin
      this.filters.filOrigem = this.$refs.origin.value ? this.$refs.origin.value : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.$refs.OriginDestinationTable.refresh()
    },
    setDefaultFilters () {
      this.filters.filOrigem = null

      this.$store.dispatch('setAppliedFilter', this.filters)
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()
      // TODO: Ezequiel

      this.setDefaultFilters()

      this.buildFilters()

      this.$refs.OriginDestinationTable.refresh()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    formatUser (value) {
      if (!value) {
        return ' - '
      }

      let position = value.indexOf('@')

      return value.substring(0, position)
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onRowClicked (row) {
      // this.showModal(row.data)
    },
    onDelete (item) {
      var _this = this
      _this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja excluir.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (e) => this.delete(item)).then((result) => {
        if (result.hasOwnProperty('value')) {
          if (result.value) {
            _this.$alertSwal.toast('Registro excluído com sucesso!')
          } else {
            _this.$alertSwal.toast('Não foi possível excluir o registro!', 'error')
          }
          this.$refs.OriginDestinationTable.refresh()
        }
      })
    },
    async delete (item) {
      let _this = this
      _this.isLoading = true
      await TaxLogisticService.deleteTaxa(item.id).then(res => {
        if (res.httpCode === 200 && res.data.status === 'ok') {
          _this.isLoading = false
        } else {
          _this.isLoading = false
        }
      }).catch(() => {
        _this.isLoading = false
      })
    }
  },
  computed: {
    fieldsTable () {
      return [
        {
          name: 'pais',
          title: 'Pais',
          sortField: 'pais',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'regiao',
          title: 'Região',
          sortField: 'regiao',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'unloccode',
          title: 'UN/LOCODE',
          sortField: 'unloccode',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: 'Origem/Destino',
          sortField: 'nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome_portal',
          title: 'Origem/Destino Portal',
          sortField: 'nome_portal',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ]
    },
    activeFilters () {
      return this.filters
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return this.$store.getters.aplliedFilters || null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .option-disabled {

  }

  .swal2-popup {
    width: inherit !important;
  }

  .btn-padding {
    padding: 0.55rem 0.30rem
  }
</style>
